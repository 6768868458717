<template>
  <LiefengContent class="manage">
    <template v-slot:title>
      选项管理
    </template>
    <template v-slot:toolsbarRight>
      <div style="display: flex; align-items: center">
        <span>排序方式:</span>
        <Select
          v-model="selectCurrent"
          style="width: 200px"
          @on-change="selectChange"
        >
          <Option v-for="item in selectData" :value="item.id" :key="item.id">{{
            item.value
          }}</Option>
        </Select>
        <Button type="primary" @click="addOption">添加选项</Button>
         <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
        <Button @click="back" type="success">返回</Button>
      </div>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="curPage"
        @hadlePageSize="hadlePageSize"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
      >
      </LiefengTable>
      <!-- 对话框 -->
      <LiefengModal
        title="新增选项"
        width="600px"
        @input="addModalFn"
        :value="addStatus"
      >
        <template v-slot:contentarea>
          <addModal
          v-if="addStatus"
            ref="addModal"
            :modalData="modalData"
            @reloadTable="reloadTable"
            @modalStatus="addModalFn"
          ></addModal>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="addModalFn(false)">取消</Button>
          <Button type="primary" @click="addModalSave" style="margin-left: 20px"
            >保存</Button
          >
        </template>
      </LiefengModal>
      <LiefengModal
        title="修改选项"
        width="600px"
        @input="changeModalFn"
        :value="changeStatus"
        @reloadTable="reloadTable"
      >
        <template v-slot:contentarea>
          <changeModal
            ref="changeModal"
            :modalData="modalData"
            @changeModalFn="changeModalFn"
            @reloadTable="reloadTable"
          ></changeModal>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="changeModalFn(false)">取消</Button>
          <Button
            type="primary"
            @click="changeModalSave"
            style="margin-left: 20px"
            >保存</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/votemanage")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import addModal from "@/views/voteother/childrens/addModal";
import changeModal from "@/views/voteother/childrens/changeModal";
import { format } from "@/utils/formatTime.js";

export default {
  data() {
    return {
      loading: true,
      //title
      selectCurrent: "",
      selectData: [
        {
          id: 1,
          value: "按序号从高到低",
        },
        {
          id: 2,
          value: "按序号从低到高",
        },
        {
          id: 3,
          value: "按投票得分从高到低",
        },
        {
          id: 4,
          value: "按投票得分从低到高",
        },
      ],
      //表头列表
      talbeColumns: [
        {
          title: "序号",
          key: "seq",
          width: 100,
          align: "center",
        },
        {
          title: "封面",
          slot: "coverImg",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("img", {
              attrs: {
                src: params.row.coverImg,
                width: "100px",
                height: "100px",
              },
            });
          },
        },
        {
          title: "标题",
          key: "title",
          align: "center",
        },
        {
          title: "详情",
          key: "subtitle",
          align: "center",
        },
        {
          title: "票数",
          key: "vateTotal",
          width: 120,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 120,
          align: "center",
        },
        {
          title: "添加人",
          key: "modifier",
          width: 120,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                    //trigger: "click",
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        // type: "default",
                        type: "info",
                        size: "small",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.changeVote(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改"
                      ),
                      h("DropdownItem", [
                        h(
                          "div",
                          {
                            on: {
                              click: () => {
                                this.delectStattusVote(params.row, 2);
                              },
                            },
                            style: {
                              textAlign: "center",
                              display:
                                params.row.status == "正常" ? "" : "none",
                            },
                          },
                          "锁定票数"
                        ),
                        h(
                          "div",
                          {
                            on: {
                              click: () => {
                                this.delectStattusVote(params.row, 1);
                              },
                            },
                            style: {
                              textAlign: "center",
                              display:
                                params.row.status == "正常" ? "none" : "",
                            },
                          },
                          "解锁票数"
                        ),
                      ]),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.delectStattusVote(params.row, 3);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除选项"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      curPage: 1,
      pageSize: 20,
      //修改对话框
      changeStatus: false,
      //新增对话框
      addStatus: false,
      modalData: {},
    };
  },
  methods: {
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "封面",
            "标题",
            "详情",
            "票数",
            "状态",
            "添加人",
            "创建时间",
          ];
          let filterVal = [
            "coverImg",
            "title",
            "subtitle",
            "vateTotal",
            "status",
            "modifier",
            "gmtCreate",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "投票选项列表"
          );
        },
      });
    },
    //modal保存
    addModalSave() {
      this.$refs.addModal.save();
    },
    //修改保存
    changeModalSave() {
      this.$refs.changeModal.save();
    },

    //删除/锁定/解锁按钮
    postDelectStattusVote(row, status) {
      this.$post("/voteapi/api/pc/componentVote/deleteVoteItem", {
        voteItemId: row.voteItemId,
        modifier: row.modifier,
        status: status,
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "操作成功",
          });
          this.hadlePageSize({
            page: this.curPage,
            pageSize: this.pageSize,
          });
        } else {
          this.$Message.error({
            background: true,
            content: "操作失败",
          });
        }
      });
    },
    delectStattusVote(row, status) {
      if (status == 3) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确定删除该选项？</p>",
          onOk: () => {
            this.postDelectStattusVote(row, status);
          },
        });
      } else {
        this.postDelectStattusVote(row, status);
      }
    },
    //下拉框改变
    selectChange(value) {
      this.$post("/voteapi/api/pc/componentVote/updateItemSeqByBusiness", {
        businessType: "2",
        voteItemSeq: value,
        businessCode: this.$route.query.id,
        page: this.curPage,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.resChange(res);
          this.total = res.maxCount;
          this.curPage = res.currentPage;
          this.pageSize = res.pageSize;
        }
      });
    },
    //新增投票选项
    addOption() {
      this.modalData = {};
      this.addStatus = true;
    },
    addModalFn(status) {
      this.addStatus = status;
    },
    reloadTable() {
      this.hadlePageSize({
        page: this.curPage,
        pageSize: this.pageSize,
      });
    },
    //修改modal状态
    changeModalFn(status) {
      this.changeStatus = status;
    },
    //返回
    back() {
      this.$router.push("/voteindex?menuId=" + this.$route.query.menuId);
    },
    //修改投票选项
    changeVote(row) {
      this.changeStatus = true;
      this.modalData = row;
    },
    //修改res中的时间、状态
    resChange(res) {
      if (res.code == 200) {
        if (res.dataList.length > 0) {
          res.dataList.map((item, index, arr) => {
            arr[index].gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm");

            switch (item.status) {
              case "1":
                arr[index].status = "正常";
                break;
              case "2":
                arr[index].status = "锁定";
                break;
              default:
                arr[index].status = "删除";
            }
          });
        }
        this.tableData = res.dataList;
      }
    },
    //分页器
    hadlePageSize(data) {
      this.$get("/voteapi/api/pc/componentVote/getVoteItemPageByBusiness", {
        businessType: "2",
        businessCode: this.$route.query.id,
        page: data.page,
        pageSize: data.pageSize,
      })
        .then((res) => {
          if (res.code == 200) {
            this.resChange(res);
            this.total = res.maxCount;
            this.currentPage = res.currentPage;
            this.pageSize = res.pageSize;
            this.loading = false;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    this.hadlePageSize({
      page: 1,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
    addModal,
    changeModal,
  },
};
</script>
    
<style scoped lang='less'>
.manage {
  /deep/.ivu-form-item {
    margin-bottom: 24px
  }
  .right-btn {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    color: #12b26d;
    height: 40px;
  }
  .ivu-select {
    margin-right: 30px;
    margin-left: 10px;
    text-align: left;
  }
}
</style>